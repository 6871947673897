import React, { useEffect } from "react";
import "./Contact.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import gsap, { Power3 } from "gsap";

function Contact() {
  let about = gsap.timeline();

  useEffect(() => {
    about.from(".contact-wrapper", {
      opacity: 0,
      y: 60,
      delay: 0,
      ease: Power3.out,
      duration: 1.5,
      stagger: 0.13,
    });
  });
  return (
    <>
      <Header />
      <section className="contact-section">
        <div className="contact-wrapper">
          <h2 className="section-heading">CONTACT</h2>
          <div className="wrapper">
            <div className="contact-left">
              <h2 className="contact-heading">Hire Me/Inquiry</h2>
              <p className="contact-desc">
                You’ll hear back from me within 2–4 business days.
                <br /> I look forward to hearing from you.
              </p>
              <div className="contact-info">
                <ul>
                  <li>
                    <a href="tel:+18073586079">
                      <span className="icon">
                        <FontAwesomeIcon icon={solid("phone")} />
                      </span>
                      <span className="text">+1 807 358 6079</span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="icon">
                        <FontAwesomeIcon icon={solid("envelope")} />
                      </span>
                      <span className="text">robinjoseph452@gmail.com</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/maps/place/Decathlon+Hennur+Road/@13.0659712,77.6500723,17z/data=!3m1!4b1!4m5!3m4!1s0x3bae19f85bf2bc3b:0xff7a8a6cc87fb624!8m2!3d13.0659571!4d77.6522935">
                      <span className="icon">
                        <FontAwesomeIcon icon={solid("location-dot")} />
                      </span>
                      <span className="text">Thunder Bay, ON, CA</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="social-info">
                <ul>
                  <li>
                    <a href="#">
                      <span className="brands">
                        <FontAwesomeIcon icon={brands("facebook")} />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="brands">
                        <FontAwesomeIcon icon={brands("instagram")} />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="brands">
                        <FontAwesomeIcon icon={brands("linkedin")} />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="brands">
                        <FontAwesomeIcon icon={brands("github")} />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="brands">
                        <FontAwesomeIcon icon={brands("behance-square")} />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="brands">
                        <FontAwesomeIcon icon={brands("dribbble")} />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="contact-right">
              <div className="form-wrapper">
                <form action="POST" data-netlify="true">
                  <div className="form-group">
                    <label for="name">Your Name</label>
                    <div className="input-wrapper">
                      <span className="icons">
                        <FontAwesomeIcon icon={solid("person")} />
                      </span>
                      <input type="text" id="name" name="name" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="email">Your Email</label>
                    <div className="input-wrapper">
                      <span className="icons">
                        <FontAwesomeIcon icon={solid("envelope")} />
                      </span>
                      <input type="text" id="email" name="email" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label for="message">Message</label>

                    <textarea
                      type="text"
                      id="message"
                      name="message"
                      width="100%"
                    ></textarea>
                  </div>
                  <button type="submit">Send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;

import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import "./About.css";
import Mine from "../../Assets/mine-about2.png";
import Cert_1 from "../../Assets/cert1.jpeg";
import Cert_2 from "../../Assets/cert2.jpg";
import Cert_3 from "../../Assets/cert3.jpg";
import Cert_4 from "../../Assets/cert4.jpg";
import Footer from "../../Components/Footer/Footer";
import gsap, { Power3 } from "gsap";

function About() {
  let about = gsap.timeline();

  useEffect(() => {
    about.from(".about-section-heading, .about-wrapper2", {
      opacity: 0,
      y: 60,
      delay: 0,
      ease: Power3.out,
      duration: 1.5,
      stagger: 0.55,
    });
    // about.from(".about-wrapper2", {
    //   opacity: 0,
    //   y: 160,
    //   delay: 2,
    //   ease: Power3.in,
    //   duration: 3,
    //   stagger: 0.25,
    // });
  });

  return (
    <div>
      <Header />
      <section className="about-section">
        <div className="about-wrapper">
          <h2 className="about-section-heading">ABOUT ME</h2>
          <div className="about-wrapper2">
            <div className="about-left">
              <img src={Mine} className="about-img" alt="" />
            </div>
            <div className="about-right">
              <p className="about-short-heading">Who am I</p>
              <h1 className="about-heading">Hii There!</h1>
              <span className="about-span">
                I’m a Web designer and developer based in Thunder Bay, CA. I
                find great joy in being a web designer and developer. My
                dedication to the web platform is driven by a desire to improve
                it for all users.<br></br>
                <br></br> Completing my{" "}
                <b>Bachelor's in Computer Application</b> from Bengaluru
                University, India, has provided me with a solid educational
                foundation. Additionally, I am currently pursuing a course in
                <b> Information Communication Technology</b>, allowing me to
                further expand my skills and knowledge in the field. Crafting
                designs and bringing them to life is my primary focus, and it is
                a passion that fills me with excitement and fulfillment.
              </span>
            </div>
          </div>
          <div className="certifications">
            <h1 className="about-heading2">Certifications</h1>
            <div className="certificates">
              <img src={Cert_1} alt="" className="certificate-img" />
              <div className="overlay">
                Foundations of User Experience (UX) Design
              </div>

              <img src={Cert_2} alt="" className="certificate-img" />
              <div className="overlay">
                React and Redux for Absolute Beginners
              </div>

              <img src={Cert_3} alt="" className="certificate-img" />
              <div className="overlay">
                Learn Javascript And JQuery From Scratch.
              </div>

              <img src={Cert_4} alt="" className="certificate-img" />
              <div className="overlay">
                HTML, Javascript, Bootstrap - Certification course
              </div>
            </div>
            <div className="about-btn">
              <a
                href="https://www.linkedin.com/in/rj-robinjoseph/details/certifications/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <span className="">
                  VIEW ALL{" "}
                  <svg
                    className="about-btn-svg"
                    width="25"
                    height="25"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;

import React, { useEffect } from "react";
import "./ProjectsCard.css";
import Project0 from "../../Assets/projects/webshop.png";
import Project1 from "../../Assets/projects/movieland.png";
import Project2 from "../../Assets/projects/movieworld.png";
import Project3 from "../../Assets/projects/movieDB.png";
import Project4 from "../../Assets/projects/utube.png";
import Project5 from "../../Assets/projects/photo-gallery.png";
import Project6 from "../../Assets/projects/project.png";
import Project7 from "../../Assets/projects/amazon-clone.png";
import Project8 from "../../Assets/projects/apple-clone.png";
import Project9 from "../../Assets/projects/benz.png";
import Project10 from "../../Assets/projects/gym.png";
import Project11 from "../../Assets/projects/superchat.png";
import Project12 from "../../Assets/projects/travel.jpg";
import Project13 from "../../Assets/projects/todo.png";
import Project14 from "../../Assets/projects/shopp.my.png";
import Project15 from "../../Assets/projects/pixabay.png";
import Project16 from "../../Assets/projects/shopeee.png";
import Project17 from "../../Assets/projects/login.png";
import Project18 from "../../Assets/projects/eventicon.png";
import Project19 from "../../Assets/projects/booksrack.png";
import Project20 from "../../Assets/projects/framework.png";
import Project21 from "../../Assets/projects/matthewclara.png";
import Project22 from "../../Assets/projects/roots.png";
import Project23 from "../../Assets/projects/quickreads.png";
import Project24 from "../../Assets/projects/parkease.png";
import Project25 from "../../Assets/projects/playful.png";
import gsap, { Expo, Power3 } from "gsap";

function ProjectsCard() {
  let projectscard = gsap.timeline();

  useEffect(() => {
    projectscard.from(".projects-card", {
      opacity: 0,
      y: 50,
      // delay: 0.2,
      ease: Power3.easeInOut,
      duration: 0.8,
      stagger: 0.08,
    });
  });

  return (
    <>
      <div className="projects-card">
        <span className="projects-span">WOOCOMMERCE</span>
        <p className="projects-p">PLAYFUL TOYS</p>
        <p className="projects-p2">Ecommerce Website using WooCommerce</p>
        <img src={Project25} className="projects-img" alt="" />
        <a href="http://playfultoys.great-site.net/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DESIGN</span>
        <p className="projects-p">PARKEASE</p>
        <p className="projects-p2">Design of a Parking App using Figma</p>
        <img src={Project24} className="projects-img" alt="" />
        <a
          href="https://www.figma.com/design/JMnU0PXazOQPSaiXmcrCVL/Park-Ease-Design"
          target="blank"
        >
          <div class="project-link-overlay-2">View Design</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">QUICKREADS</p>
        <p className="projects-p2">News website using wordpress</p>
        <img src={Project23} className="projects-img" alt="" />
        <a href="https://quickreads.infinityfreeapp.com/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">ROOTS HAIR SALOON</p>
        <p className="projects-p2">SPA using Next.js</p>
        <img src={Project22} className="projects-img" alt="" />
        <a href="https://roots-hair-salon.vercel.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">PORTFOLIO</p>
        <p className="projects-p2">An artist Portfolio using Wordpress</p>
        <img src={Project21} className="projects-img" alt="" />
        <a href="http://matthewclara.free.nf/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">SHOPIFY</span>
        <p className="projects-p">FRAMEWORK</p>
        <p className="projects-p2">Ecommerce using Shopify. Password: 1234</p>
        <img src={Project20} className="projects-img" alt="" />
        <a href="http://robin-joseph.myshopify.com/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">BOOKSRACK</p>
        <p className="projects-p2">Books website</p>
        <img src={Project19} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Booksrack" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://rj-robinjoseph.github.io/Booksrack/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">WEBSHOP</p>
        <p className="projects-p2">Ecommerce app using React.js</p>
        <img src={Project0} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Webshop" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://webshop-react-project.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">MOVIELAND</p>
        <p className="projects-p2">A Movie app using MERN stack</p>
        <img src={Project1} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/MovieLand" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://movieland-mern.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">MOVIEWORLD</p>
        <p className="projects-p2">A movie website made using HTML, CSS, JS</p>
        <img src={Project2} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Movieworld" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://movieworld-rj.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">MOVIEDB</p>
        <p className="projects-p2">
          A movie database app made using JS, TMDB Api
        </p>
        <img src={Project3} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/MovieDB" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://moviedb-tmdb.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">UTUBE</p>
        <p className="projects-p2">Youtube modified clone using Youtube Api</p>
        <img src={Project4} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/UTube." target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://utube-api.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">PHOTO GALLERY</p>
        <p className="projects-p2">
          Photo App which allows to upload fav photos
        </p>
        <img src={Project5} className="projects-img" alt="" />
        <a
          href="https://github.com/rj-robinjoseph/Photo-Gallery"
          target="blank"
        >
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://photo-gallery-reactjs.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">PROJECT MANAGER</p>
        <p className="projects-p2">An app to manage projects</p>
        <img src={Project6} className="projects-img" alt="" />
        <a
          href="https://github.com/rj-robinjoseph/Project-Manager"
          target="blank"
        >
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://project-manager-reactjs.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">PIXABAY</p>
        <p className="projects-p2">A photo app using unsplash Api</p>
        <img src={Project15} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/PixaBay" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://pixabay-photo-app.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DESIGN</span>
        <p className="projects-p">SHOPP.MY</p>
        <p className="projects-p2">Designed using Adobe XD</p>
        <img src={Project14} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Shopp.my" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://ecommerce-design-shoppmy.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">SHOPEEE</p>
        <p className="projects-p2">An Ecommerce website landing page</p>
        <img src={Project16} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Shopeee" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://shopeee4shoes.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DESIGN</span>
        <p className="projects-p">LOGIN</p>
        <p className="projects-p2">A Login page design using Figma</p>
        <img src={Project17} className="projects-img" alt="" />
        <a
          href="https://www.behance.net/gallery/140486013/Login-Page-design"
          target="blank"
        >
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DESIGN</span>
        <p className="projects-p">EVENTICON</p>
        <p className="projects-p2">An event management website design</p>
        <img src={Project18} className="projects-img" alt="" />
        <a
          href="https://www.behance.net/gallery/140486013/Login-Page-design"
          target="blank"
        >
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">AMAZON CLONE</p>
        <p className="projects-p2">Clone website of Amazon.com</p>
        <img src={Project7} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Amazon-clone" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a
          href="https://amazon-clone-reactjs-firebase.netlify.app/"
          target="blank"
        >
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">APPLE CLONE</p>
        <p className="projects-p2">Clone website of Apple.com</p>
        <img src={Project8} className="projects-img" alt="" />
        <a
          href="https://github.com/rj-robinjoseph/Apple-Modified-Clone"
          target="blank"
        >
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://apple-landing.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">BENZ</p>
        <p className="projects-p2">Benz landing page</p>
        <img src={Project9} className="projects-img" alt="" />
        <a
          href="https://github.com/rj-robinjoseph/Benz-LandingPage"
          target="blank"
        >
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a
          href="https://github.com/rj-robinjoseph/Benz-LandingPage"
          target="blank"
        >
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">GYM</p>
        <p className="projects-p2">Gym website</p>
        <img src={Project10} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/GYM-Fitness" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://github.com/rj-robinjoseph/GYM-Fitness" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">SUPERCHAT</p>
        <p className="projects-p2">A chatroom app using React.js</p>
        <img src={Project11} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Superchat" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://github.com/rj-robinjoseph/Todo-App" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">TRVL</p>
        <p className="projects-p2">A travel website</p>
        <img src={Project12} className="projects-img" alt="" />
        <a
          href="https://github.com/rj-robinjoseph/Travel-App-landing-page"
          target="blank"
        >
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a
          href="https://travel-landing-page-reactjs.netlify.app/"
          target="blank"
        >
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
      <div className="projects-card">
        <span className="projects-span">DEVELOPMENT</span>
        <p className="projects-p">TODO</p>
        <p className="projects-p2">An todo app using React.js</p>
        <img src={Project13} className="projects-img" alt="" />
        <a href="https://github.com/rj-robinjoseph/Todo-App" target="blank">
          <div class="project-link-overlay">View on Github</div>
        </a>
        <a href="https://todo-reactjs-rj.netlify.app/" target="blank">
          <div class="project-link-overlay-2">View Live Project</div>
        </a>
      </div>
    </>
  );
}

export default ProjectsCard;

import React, { useEffect } from "react";
import "./Projects.css";
import Header from "../../Components/Header/Header";
import ProjectsCard from "../../Components/ProjectsCard/ProjectsCard";
import gsap, { Expo, Power3 } from "gsap";
import Footer from "../../Components/Footer/Footer";

function Projects() {
  let projects = gsap.timeline();

  useEffect(() => {
    projects.from(".project-section-heading", {
      opacity: 0,
      y: 60,
      delay: 0,
      ease: Power3.out,
      duration: 1.5,
    });
  });

  return (
    <div>
      <Header />
      <div className="project-section">
        <div className="project-wrapper">
          <h2 className="project-section-heading">PROJECTS</h2>
          <div className="projects">
            <ProjectsCard />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Projects;

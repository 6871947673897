import React from "react";
import "./Footer.css";
import Logo from "../../Assets/logo-rj.png";
import github from "../../Assets/icons/github-b.png";
import linkedin from "../../Assets/icons/linkedin-b.png";
import instagram from "../../Assets/icons/instagram-b.png";
import behance from "../../Assets/icons/behance-b.png";
import facebook from "../../Assets/icons/facebook-b.png";
import dribbble from "../../Assets/icons/dribbble-b.png";

function Footer() {
  return (
    <div className="contact-me">
      <div className="contact-me-wrapper">
        <p className="contact-me-title">Let's Make Something Together</p>
        <p className="contact-me-subtitle">Start By Saying Hi.</p>
        <div className="footer-end">
          <img src={Logo} alt="" className="footer-logo" />
          <div className="footer-end-left">
            <span className="footer-end-line"></span>
            <p id="Email">
              <a href="mailto: robinjoseph452@gmail.com" className="email-link">
                <span className="email">robinjoseph452@gmail.com</span>
              </a>
            </p>
            <p id="PhoneNo">
              <a href="tel: +18073586079" className="phone-link">
                <span className="phone">+1 807 358 6079</span>
              </a>
            </p>
          </div>
          <div className="footer-end-right-main">
            <span className="footer-end-line"></span>
            <div className="footer-end-right">
              <a
                href="https://github.com/rj-robinjoseph"
                target="_blank"
                rel="noreferrer"
              >
                <img src={github} />
              </a>
              <a
                href="https://www.linkedin.com/in/rj-robinjoseph/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} />
              </a>
              <a
                href="https://www.instagram.com/rj_robinjoseph/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} />
              </a>
              <a
                href="https://www.facebook.com/robinjosephrj452/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} />
              </a>
              <a
                href="https://www.behance.net/robinjoseph2"
                target="_blank"
                rel="noreferrer"
              >
                <img src={behance} />
              </a>
              <a
                href="https://dribbble.com/rj_robinjoseph"
                target="_blank"
                rel="noreferrer"
              >
                <img src={dribbble} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

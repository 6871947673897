import "./Home.css";
import { React, useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import Works from "../../Components/Works/Works";
import Hero from "../../Components/Hero/Hero";
import About from "../../Components/About/About";
import Skills from "../../Components/Skills/Skills";
import Footer from "../../Components/Footer/Footer";
import lottie from "lottie-web";

function Home() {
  const container = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);

  useEffect(() => {
    setTimeout(() => {
      setData(true);
      setloading(true);

      setTimeout(() => {
        setcompleted(true);
      }, 1000);
    }, 1000);
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../Assets/preloader.json"),
    });
  }, []);

  return (
    <div className="home">
      {!completed ? (
        <div className="loader">
          <div className="loader-container" ref={container}></div>
        </div>
      ) : (
        <>
          <Header />
          <Hero />
          <About />
          <Works />
          <Skills />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;

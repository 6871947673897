import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
// import MessengerCustomerChat from "react-messenger-customer-chat";
// import { TransitionGroup, Transition } from "react-transition-group";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Projects from "./Pages/Projects/Projects";
import Contact from "./Pages/Contact/Contact";
import Resume from "./Pages/Resume/Resume";
import gsap from "gsap";
import { useEffect, useRef } from "react";
// import CustomCursor from "./Components/CustomCursor/CustomCursor";

const App = () => {
  let cursor = useRef(null);
  let posX1 = useRef(null);
  let posY1 = useRef(null);
  let mouseX1 = useRef(null);
  let mouseY1 = useRef(null);

  let tl = gsap.timeline();
  let tl2 = gsap.timeline();

  useEffect(() => {
    let posX = posX1.current;
    let posY = posY1.current;
    let mouseX = mouseX1.current;
    let mouseY = mouseY1.current;
    tl.to({}, 0.016, {
      repeat: -1,
      onRepeat: function () {
        posX += (mouseX - posX) / 10;
        posY += (mouseY - posY) / 10;
        tl.set(cursor, {
          css: {
            left: posX - 50,
            top: posY - 50,
          },
        });
      },
    });
    document.addEventListener("mousemove", function (e) {
      mouseX = e.pageX;
      mouseY = e.pageY;
    });
    tl2.from(
      cursor,
      {
        duration: 1.5,
        delay: 2,
        opacity: 0,
      },
      "-=1"
    );
  });

  return (
    <div>
      {/* <CustomCursor /> */}
      <div className="noise"></div>

      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/about" element={<About />} />

          <Route exact path="/projects" element={<Projects />} />

          <Route exact path="/contact" element={<Contact />} />

          <Route exact path="/resume" element={<Resume />} />
        </Routes>
        <div className="cursor-follower" ref={(el) => (cursor = el)}></div>
      </Router>

      {/* <MessengerCustomerChat
        pageId="110691825006271"
        appId="1190232571813407"
      /> */}
    </div>
  );
};

export default App;

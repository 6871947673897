import React from "react";
import ResumeImg from "../../Assets/Resume.png";
import Resume1 from "../../Assets/Resume-1.webp";
import Resume2 from "../../Assets/Resume-2.webp";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import "./Resume.css";
import Header from "../../Components/Header/Header";

function Resume() {
  return (
    <div>
      <Header />
      <img src={Resume1} alt="" className="resume" />
      <img src={Resume2} alt="" className="resume" />
      <div className="routing">
        <div className="routing-main">
          <Link
            to="/"
            className="resume-link"
            style={{ textDecoration: "none" }}
          >
            <span className="routing-links">HOME</span>
          </Link>
          <Link
            to="/about"
            className="resume-link"
            style={{ textDecoration: "none" }}
          >
            <span className="routing-links">ABOUT</span>
          </Link>
          <Link
            to="/projects"
            className="resume-link"
            style={{ textDecoration: "none" }}
          >
            <span className="routing-links">PROJECTS</span>
          </Link>
          <Link
            to="/contact"
            className="resume-link"
            style={{ textDecoration: "none" }}
          >
            <span className="routing-links">CONTACT</span>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Resume;

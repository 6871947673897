import { React, useEffect, useRef } from "react";
import "./Hero.css";
import { init } from "ityped";
import Flower1 from "../../Assets/flower.svg";
import { gsap, Expo, Power3 } from "gsap";
import lottie from "lottie-web";

function Hero() {
  const textRef = useRef();
  let background_title = useRef(null);
  let social_li2 = useRef(null);
  let social_li3 = useRef(null);
  let scrollDown = useRef(null);

  let hero = gsap.timeline();

  useEffect(() => {
    init(textRef.current, {
      showCursor: false,
      backDelay: 1500,
      backSpeed: 60,
      strings: [
        "Network Enthusiast.",
        "Full Stack Developer.",
        "Ui/Ux Designer.",
      ],
    });
  }, []);

  useEffect(() => {
    lottie.loadAnimation({
      container: scrollDown.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../Assets/scroll-down2.json"),
    });
  });

  useEffect(() => {
    hero.fromTo(
      "#HiThere h1",
      {
        y: "100%",
        opacity: 0,
        skewY: "10",
      },
      {
        y: "0%",
        duration: 1.5,
        opacity: 1,
        skewY: "0",
        ease: Expo.easeInOut,
      }
    );

    hero.from(
      ".main-heading-div, .top-main-heading, .bottom-main-heading",
      {
        opacity: 0,
        y: 60,
        delay: 0,
        ease: Power3.out,
        duration: 1,
        stagger: 0.18,
      },
      1.2
    );

    hero.from(
      ".scroll-down",
      {
        opacity: 0,
        y: 60,
        delay: 1,
        ease: Power3.out,
        duration: 1.5,
      },
      1.5
    );
  });

  return (
    <div className="hero">
      <div id="HiThere">
        <h1>
          <span className="hi">Hi</span>
          <br />
          <span className="there"> There.</span>
        </h1>
      </div>
      <div className="main-wrapper">
        <div className="main">
          <h2 className="top-main-heading">I'M</h2>
          <div className="main-heading-div">
            {/* <h1 className="main-heading">Robin Joseph</h1> */}
            <span className="main-heading">Robin</span>
            &nbsp;
            <span className="main-heading">Joseph</span>
          </div>
          <h2 className="bottom-main-heading" ref={textRef} />
          <div className="scroll-down" ref={scrollDown}></div>
        </div>
      </div>
      <img className="flower" src={Flower1} alt="" />
    </div>
  );
}

export default Hero;

import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Works.css";
import arrow from "../../Assets/arrow.png";
import hoverEffect from "hover-effect";
import overlay from "../../Assets/overlay.png";
import stripe from "../../Assets/stripe.png";
import Project1 from "../../Assets/projects/playful.png";
import Project2 from "../../Assets/projects/framework.png";
import Project3 from "../../Assets/projects/quickreads.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Works() {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);
  useEffect(() => {
    var image_animate_1 = new hoverEffect({
      parent: document.querySelector(".item-image-1"),
      intensity: 0.05,
      image1: Project1,
      image2: Project1,
      displacementImage: overlay,
    });
  });
  useEffect(() => {
    var image_animate_2 = new hoverEffect({
      parent: document.querySelector(".item-image-2"),
      intensity: 0.05,
      image1: Project2,
      image2: Project2,
      displacementImage: overlay,
    });
  });
  useEffect(() => {
    var image_animate_3 = new hoverEffect({
      parent: document.querySelector(".item-image-3"),
      intensity: 0.05,
      image1: Project3,
      image2: Project3,
      displacementImage: overlay,
    });
  });

  return (
    <div data-aos="fade-up">
      <h1 className="works">RECENT WORKS</h1>
      <div className="view-all">
        <Link to="/projects" style={{ textDecoration: "none" }}>
          <span className="">
            VIEW ALL{" "}
            <svg
              className="view-all-svg"
              width="25"
              height="25"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 7.5C3 7.32953 3.06584 7.16605 3.18304 7.04551C3.30024 6.92498 3.45919 6.85726 3.62493 6.85726H10.8654L8.18192 4.09861C8.06458 3.97792 7.99865 3.81423 7.99865 3.64355C7.99865 3.47287 8.06458 3.30918 8.18192 3.18849C8.29927 3.0678 8.45842 3 8.62437 3C8.79032 3 8.94948 3.0678 9.06682 3.18849L12.8164 7.04494C12.8746 7.10464 12.9208 7.17557 12.9523 7.25366C12.9838 7.33175 13 7.41546 13 7.5C13 7.58454 12.9838 7.66825 12.9523 7.74634C12.9208 7.82443 12.8746 7.89536 12.8164 7.95506L9.06682 11.8115C8.94948 11.9322 8.79032 12 8.62437 12C8.45842 12 8.29927 11.9322 8.18192 11.8115C8.06458 11.6908 7.99865 11.5271 7.99865 11.3564C7.99865 11.1858 8.06458 11.0221 8.18192 10.9014L10.8654 8.14274H3.62493C3.45919 8.14274 3.30024 8.07502 3.18304 7.95449C3.06584 7.83395 3 7.67047 3 7.5Z"
                fill="black"
              ></path>
            </svg>
          </span>
        </Link>
      </div>

      <div className="project-page-container">
        <div className="project-page-items">
          <div className="project-page-item">
            <a href="https://playfultoys.great-site.net/" target="blank">
              <div className="project-page-item-header">
                <h1 style={{ textDecoration: "none" }}>PLAYFUL TOYS</h1>
                <p>DEVELOPMENT</p>
              </div>
              <div className="project-page-item-image project-page-item-image1 item-image-1"></div>
              <div className="project-page-item-footer">
                <p>Ecommerce Website using WooCommerce.</p>
                <img src={arrow} alt="" />
              </div>
            </a>
          </div>
          <div className="project-page-item">
            <a href="https://robin-joseph.myshopify.com/" target="blank">
              <div className="project-page-item-header">
                <h1>FRAMEWORK</h1>
                <p>DEVELOPEMENT</p>
              </div>
              <div className="project-page-item-image project-page-item-image2 item-image-2"></div>
              <div className="project-page-item-footer">
                <p>Ecommerce using Shopify. Password: 1234.</p>
                <img src={arrow} alt="" />
              </div>
            </a>
          </div>
          <div className="project-page-item">
            <a href="https://quickreads.infinityfreeapp.com/" target="blank">
              <div className="project-page-item-header">
                <h1>QUICKREADS</h1>
                <p>DEVELOPEMENT</p>
              </div>
              <div className="project-page-item-image project-page-item-image3 item-image-3"></div>
              <div className="project-page-item-footer">
                <p>News website using wordpress.</p>
                <img src={arrow} alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;

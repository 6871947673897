import React from "react";
import "./Skills.css";
import HTML from "../../Assets/html.png";
import CSS from "../../Assets/css.png";
import JS from "../../Assets/js.png";
import REACT from "../../Assets/structure.png";
import XD from "../../Assets/xd.png";
import PS from "../../Assets/photoshop.png";
import IL from "../../Assets/illustrator.png";
import FIGMA from "../../Assets/figma.png";
import MONGODB from "../../Assets/mongodb.png";
import NODE from "../../Assets/node-js.png";
import WP from "../../Assets/wordpress.png";
import SHOPIFY from "../../Assets/shopify.png";
import NEXT from "../../Assets/nextjs.png";

function Skills() {
  return (
    <>
      <div className="my-skills-main-reel">
        <div className="my-skills-reel" id="skill-reel">
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
          <div className="reel-item">&nbsp; -- My skills</div>
        </div>
        <p>AN OVERVIEW OF MY CAPABILITIES</p>
      </div>
      <div className="skill-set-boxes">
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={HTML} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">HTML5</h1>
          </div>
          <p className="skill-set-box-p">
            I mainly used to develop Website Markup
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={CSS} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">CSS 3</h1>
          </div>
          <p className="skill-set-box-p">
            I use this to style and bring design to browsers
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={JS} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">JS</h1>
          </div>
          <p className="skill-set-box-p">
            With this technology I create visual effects and interact with dom
            elements
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={REACT} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">REACT</h1>
          </div>
          <p className="skill-set-box-p">
            I loved it! I use it to create applications that have a lot of
            reactivity
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={NEXT} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">NEXT.JS</h1>
          </div>
          <p className="skill-set-box-p">
            I use Next.js for building fast, server-rendered web applications
            with React.
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={XD} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">XD</h1>
          </div>
          <p className="skill-set-box-p">
            I used it to create prototypes, designs, and mockups
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={FIGMA} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">FIGMA</h1>
          </div>
          <p className="skill-set-box-p">
            I used it to create prototypes, designs, and mockups
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={MONGODB} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">MONGO</h1>
          </div>
          <p className="skill-set-box-p">
            I use this NoSQL database to store and retrieve data
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={NODE} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">NODE.JS</h1>
          </div>
          <p className="skill-set-box-p">
            I use it for writing server scripting for applications
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={WP} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">WORDPRESS</h1>
          </div>
          <p className="skill-set-box-p">
            Used to create stunning websites and E-Commerce applications.
          </p>
          <p className="skill-line">___</p>
        </div>
        <div className="skill-set-box">
          <div className="skill-set-box-main">
            <img src={SHOPIFY} alt="" className="skill-set-box-main-img" />
            <h1 className="skill-set-box-h1">SHOPIFY</h1>
          </div>
          <p className="skill-set-box-p">
            Used to build fully functional E-Commerce websites.
          </p>
          <p className="skill-line">___</p>
        </div>
      </div>
    </>
  );
}

export default Skills;

import "./Header.css";
import React, { useRef, useEffect, useState } from "react";
import useScrollListener from "../../Hooks/useScrollListener";
import Logo from "../../Assets/logo-rj.png";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "../../Assets/menu.png";
import CloseIcon from "../../Assets/close.png";
import github from "../../Assets/icons/github.png";
import linkedin from "../../Assets/icons/linkedin.png";
import instagram from "../../Assets/icons/instagram.png";
import behance from "../../Assets/icons/behance.png";
import facebook from "../../Assets/icons/facebook.png";
import dribbble from "../../Assets/icons/dribbble.png";
import gsap, { Expo } from "gsap";

function Header({ timeline }) {
  let li1 = useRef(null);
  let li2 = useRef(null);
  let li3 = useRef(null);
  let li4 = useRef(null);
  let menu = useRef(null);
  let close = useRef(null);
  let social_li1 = useRef(null);
  let social_li2 = useRef(null);
  let social_li3 = useRef(null);
  let social_li4 = useRef(null);
  let social_li5 = useRef(null);
  let social_li6 = useRef(null);

  let head = gsap.timeline({ paused: true });
  let hero_anime = gsap.timeline();

  function toggle() {
    head.play();
    document.querySelector("body").style.overflow = "hidden";
  }
  function togglec() {
    head.reverse();
    document.querySelector("body").style.overflowY = "auto";
  }
  function auto() {
    head.reverse();
    document.querySelector("body").style.overflowY = "auto";
  }

  useEffect(() => {
    head.to(menu, {
      duration: 0.8,
      x: "0%",
      ease: Expo.easeInOut,
    });
    head.fromTo(
      [li1, li2, li3, li4],
      {
        y: "-100%",
        opacity: 0,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: "0%",
        stagger: {
          amount: 0.25,
        },
      }
    );
    head.fromTo(
      [
        social_li1,
        social_li2,
        social_li3,
        social_li4,
        social_li5,
        social_li6,
        close,
      ],
      {
        y: "-50%",
        opacity: 0,
      },
      {
        duration: 0.6,
        opacity: 1,
        stagger: {
          amount: 0.2,
        },
        ease: Expo.easeOut,
      },
      "-=0.5"
    );
  });

  // useEffect(() => {
  //   hero_anime.fromTo(
  //     ".main-logo-img, .menu-icon",
  //     {
  //       opacity: 0,
  //       yPercent: -50,
  //       duration: 2,
  //     },
  //     {
  //       opacity: 1,
  //       yPercent: 0,
  //       ease: "power3.out",
  //     },
  //     3
  //   );
  // });

  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScrollListener();

  // update classList of nav on scroll
  useEffect(() => {
    const _classList = [];

    if (scroll.y > 100 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-bar--hidden");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  return (
    <div>
      <header className={navClassList.join(" ")}>
        <div className="container">
          <div id="logo" className="main-logo">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img className="main-logo-img" src={Logo} alt="" />
            </Link>
          </div>
          <div className="menu-container">
            <div className="button" onClick={toggle}>
              <img src={MenuIcon} alt="" className="menu-icon" />
            </div>
          </div>
        </div>
        <div ref={(el) => (menu = el)} className="menu">
          <div className="button close" onClick={togglec}>
            <img src={CloseIcon} ref={(el) => (close = el)} alt="" />
          </div>

          <ul className="ul">
            <li ref={(el) => (li1 = el)} className="li1 li">
              <Link to="/" style={{ textDecoration: "none" }} onClick={auto}>
                <p>Home</p>
              </Link>
            </li>
            <li ref={(el) => (li2 = el)} className="li2 li">
              <Link
                to="/about"
                style={{ textDecoration: "none" }}
                onClick={auto}
              >
                <p>About</p>
              </Link>
            </li>
            <li ref={(el) => (li3 = el)} className="li3 li">
              <Link
                to="/projects"
                style={{ textDecoration: "none" }}
                onClick={auto}
              >
                <p>Projects</p>
              </Link>
            </li>
            <li ref={(el) => (li4 = el)} className="li4 li">
              <Link
                to="/contact"
                style={{ textDecoration: "none" }}
                onClick={auto}
              >
                <p>Contact</p>
              </Link>
            </li>
            <div className="bg1 bg"></div>
            <div className="bg2 bg"></div>
            <div className="bg3 bg"></div>
            <div className="bg4 bg"></div>
          </ul>
          <div className="social">
            <ul>
              <li ref={(el) => (social_li1 = el)} className="social-li">
                <a
                  href="https://github.com/rj-robinjoseph"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={github} alt="" />
                </a>
              </li>
              <li ref={(el) => (social_li2 = el)} className="social-li">
                <a
                  href="https://www.linkedin.com/in/rj-robinjoseph/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedin} alt="" />
                </a>
              </li>
              <li ref={(el) => (social_li3 = el)} className="social-li">
                <a
                  href="https://www.facebook.com/robinjosephrj452/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="" />
                </a>
              </li>
              <li ref={(el) => (social_li4 = el)} className="social-li">
                <a
                  href="https://www.instagram.com/rj_robinjoseph/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="" />
                </a>
              </li>
              <li ref={(el) => (social_li5 = el)} className="social-li">
                <a
                  href="https://www.behance.net/robinjoseph2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={behance} alt="" />
                </a>
              </li>
              <li ref={(el) => (social_li6 = el)} className="social-li">
                <a
                  href="https://dribbble.com/rj_robinjoseph"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={dribbble} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
